import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const recommendations = (props) => (
	<Layout>
		<div>
			<Helmet>
				<title>Professional Recommendations | Jonathan Agoot</title>
				<meta name="description" content="Jonathan Agoot - Professional Recommendations" />
			</Helmet>
			<div id="main">
				<section id="banner" className="style1">
					<div className="inner">
						<header className="major">
							<h1>Professional Recommendations</h1>
						</header>
						<div className="content">
							<p>I've enjoyed working with colleagues from a variety of backgrounds. Here's what they have to say about collaborating and innovating with me.</p>
						</div>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Suzanne Kongkeo</h3>
						<h4>Assoc. Director, Marketing Development at Thermo Fisher Scientific</h4>
						<p className="blockquote">"Jonathan worked on many exciting data science projects that helped propel the company to be one of the leaders in our space. He was incredibly passionate about his ideas and communicated them in a way that was contagious."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Catrin Walsh</h3>
						<h4>Sr. Director, Brand Management at AMN Healthcare</h4>
						<p className="blockquote">"Jonathan is passionate visionary who thrives on improving the business to better serve its customers. He is an idea generator with a start-up mindset and collaborative approach, which has enabled him to be nimble in a large organization such as ours. What really impresses me about Jonathan is that he is not only able to constantly come up with novel ideas; he is able go from concept to MVP quickly all while using a 'test and learn' approach. Another thing that strikes me about Jonathan, is that his work is his passion. He frequently comes back after a weekend having thought of a new idea and quickly put together a prototype, ready for stakeholder and customer feedback. I highly recommend Jonathan and hope to work with him in the future."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Michael Rasmussen</h3>
						<h4>Sr. Financial Analyst at Thermo Fisher Scientific</h4>
						<p className="blockquote">"Jonathan has an uncanny ability to marry emerging technology with the needs of his projects and company. He is a big picture thinker and collaborates efficiently. Jonathan also does an amazing job of interviewing experts as a means of refining and expanding his visions for what is possible."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Ambika Elangovan</h3>
						<h4>Product Owner, eCommerce &amp; Omni Channel Professional at Thermo Fisher Scientific</h4>
						<p className="blockquote">"I worked with Jonathan on a few key strategic initiatives in Thermofisher and he has become a HUGE mentor of mine. His astute knowledge of the industry, latest technology trends combined with the business know-how’s was a huge asset. During the discussions I’ve had with him, I noticed one thing prominent about him, he always had innovation and efficiency on his mind. He goes out of his way to help everyone grow within the organization. He was very instrumental in training new hires by sharing his knowledge. He is truly a great deal maker and a strategist, easily on par with the best of the senior executives I have worked with here."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Evihn W. Vaszily</h3>
						<h4>Holistic Digital Marketing Leader at Epsilon</h4>
						<p>"Few colleagues exhibit the consistent, iterative innovations that Jonathan introduces to improve results in whatever team he is a part of. I had the pleasure of working with Jonathan on the conversion rate optimization team at Thermo Fisher Scientific, and there's no doubt he played an integral part in moving eCommerce to a place of constant improvement. Jonathan is a visionary, and I would highly recommend his talents to any organization looking to move toward greater efficiency and efficacy."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Diana Lewis</h3>
						<h4>Research and Development Scientist at Thermo Fisher Scientific</h4>
						<p>"Jonathan is not only an intelligent and ambitious strategist but also an inspiring colleague. He introduced data science in to existing digital marketing programs, which transformed how we market and sell. Passionate about the startup ecosystem, he maintains very good relations with co-workers and clients from around the world. Highly recommended."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Diana Vargas</h3>
						<h4>Senior Global Marketing Specialist at Thermo Fisher Scientific</h4>
						<p>"I worked with Jonathan for over 6 years and had the opportunity to learn a ton from him. He's amazing at breaking down processes step by step and teaching others in order to enable them to get things done. Jonathan has a great aptitude within the technology and data science space that can help individuals and companies to advance their business. In addition to all his knowledge he is also very creative and innovative."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>John Zavocki</h3>
						<h4>Sr. UX Developer &amp; Analyst at Thermo Fisher Scientific</h4>
						<p>"Jon and I worked together on many projects for Thermo Fisher Scientific."</p>
						<p>"Over the years, his breadth of knowledge from server-side apps to eBusiness analytics are only second to his consistently spot-on prediction of game-changing future trends. Jon has always made himself available to me when I needed to gain a deeper understanding of Thermo’s customers/personas. Often, when asked a pointed question, an accurate statistic will pop out of his mouth - like all the information within was waiting to be accessed."</p>
						<p>"Also, he the rarest combination of a Connector, Maven, and Salesman rolled into one; only the second such individual I have met in my life. If you are looking for someone to will push the envelope and know how / where to push it - I can’t think of anyone better than Jon."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>William Dietrich</h3>
						<h4>Associate Director, Global Marketing Management at Thermo Fisher Scientific</h4>
						<p>"I worked with Jonathan for many years during his tenure with Invitrogen, Life Technologies and now Thermo Fisher Scientific; I can say first hand that his passion for excellence and innovation combined with his knowledge and expertise in digital technologies and in general, new and novel technologies is nothing short of amazing. He understands (and if he doesn't, he'll learn it and learn it fast) not only the technology and how to implement it, he also understand how it can and will make customer's day that much easier. His approach to utilizing the best, most innovative technology is an asset to any company looking to be at the forefront in their market."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Kevin Zhao</h3>
						<h4>Associate Director, Marketing Analytics at Thermo Fisher Scientific</h4>
						<p>"I worked with Jonathan for 7 years at ThermoFisher (Former Life Technologies) in lots of projects. Jonathan has been always innovative, collaborative and supportive in driving forward the projects. Jonathan demonstrated his ability of strategic planning and thinking big pictures and out of the box. In addition, Jonathan is always eager to help others with his expertise in digital marketing and web expertise. He is a very valuable asset to the company. I would recommend Jonathan without reservations."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>BreAnne Custodio</h3>
						<h4>Founder at Catalyst Boom!</h4>
						<p>"Jonathan manages his projects with passion and energy. He is always researching the latest and greatest technology options in the digital marketing space. He genuinely cares about the outcomes of projects and is a great asset to his digital team."</p>
					</div>
				</section>
				<section>
					<div className="inner">
						<h3>Patrick Reynolds</h3>
						<h4>Product Designer at Affirm Inc.</h4>
						<p>"Jonathan served as a great business mentor while I was working as an intern at Life Technologies. Being an effective communicator, strategic forward-thinker, and technical specialist, he had endless advise to offer. Above all, you can always count on Jonathan to bring positive, high energy to any team or project."</p>
					</div>
				</section>
			</div>
		</div>
	</Layout>
)

export default recommendations
